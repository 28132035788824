import React, { Fragment } from 'react';
import styled from 'styled-components';

import { ReactComponent as Ellipse } from '../../assets/ellipse.svg';
import { Container, SectionContainer } from '../../shared/components/UI/Container';
import PromotionCard from '../../shared/components/InfoCards/PromotionCard';
import ContactCard from '../../shared/components/InfoCards/ContactCard';
import ImageLink from '../components/ImageLink';
import AboutIllustration from '../../assets/about.png';

const ProductSection = styled.section`
  position: relative;
  background: ${(props) => `${props.theme.colors.secondary}`};
  margin-bottom: 150px;

  ${Container} {
    padding: 150px 0 150px 0;

    @media ${(props) => `${props.theme.device.laptopL}`} {
      padding: 250px 0 150px 0;
    }

    & > img {
      display: none;

      @media ${(props) => `${props.theme.device.laptop}`} {
        display: block;
      }
    }

    ${SectionContainer} {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

const Links = () => {
  return (
    <Fragment>
      <ProductSection>
        <Ellipse className='ellipse large top right' />
        <Ellipse className='ellipse large bottom left' />
        <Container className='relative z-index-1 center-middle'>
          <SectionContainer>
            <ImageLink image={AboutIllustration} text="Instagram" link='https://www.instagram.com/koaasy/'/>
            <ImageLink image={AboutIllustration} text="Iconfinder" link='https://www.iconfinder.com/koaasy'/>
            <ImageLink image={AboutIllustration} text="Iconscout" link='https://iconscout.com/contributors/koaasy'/>
            <ImageLink image={AboutIllustration} text="Tip me" link='https://ko-fi.com/koaasy'/>
            <ImageLink image={AboutIllustration} text="Freepik" link='https://www.freepik.com/author/koaasy'/>
            <ImageLink image={AboutIllustration} text="Flaticon" link='https://www.flaticon.com/authors/koaasy'/>
            <ImageLink image={AboutIllustration} text="Dribble" link='https://dribbble.com/koaasy'/>
            <ImageLink image={AboutIllustration} text="Pinterest" link='https://www.pinterest.nz/koaasythings/'/>
            <ImageLink image={AboutIllustration} text="Youtube" link='https://www.youtube.com/channel/UCym1vEEOkhMQvnr40u0UgkQ'/>
          </SectionContainer>
        </Container>
      </ProductSection>
      <ContactCard />
      <PromotionCard />
    </Fragment>
  );
};

export default Links;
