import React from 'react';
import styled from 'styled-components';
import { Container, HalfSectionContainer } from '../UI/Container';

import ContactIllustration from '../../../assets/contact.png';
import { ReactComponent as EllipseGrey } from '../../../assets/ellipseGrey.svg';
import { StyledLinkButton } from '../UI/StyledLink';
import { BsArrowRight } from 'react-icons/bs';

const StyledContactCardWrapper = styled.div`
  ${Container} {
    img {
      width: 100%;
      max-width: 500px;
      
      @media ${(props) => `${props.theme.device.laptop}`} {
        width: 50%;
        display: block;
      }
    }
  }
`;

const StyledContactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10px 10px;
  position: relative;
  overflow: hidden;
  background: ${(props) => `${props.theme.colors.bgDarkGrey}`};
  border-radius: ${(props) => `${props.theme.cardBorderRadius}`};

  @media ${(props) => `${props.theme.device.laptop}`} {
    padding: 10px 60px;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const ContactCard = () => {
  return (
    <StyledContactCardWrapper>
      <Container className='relative mb-10'>
        <StyledContactCard>
          <EllipseGrey className='ellipse top right' />
          <EllipseGrey className='ellipse bottom left' />
          <HalfSectionContainer className='relative'>
            <h2 className='mb-3 black'>Do you have an idea or need some custom illustrations?</h2>
            <p className='mb-5'>
              I would love to hear from you, contact me if you require any type of custom illustrations. I am always
              available to work on a custom project. Let's talk!
            </p>
            <StyledLinkButton to='/contact'>
              Contact me
              <BsArrowRight className='ms-05' />
            </StyledLinkButton>
          </HalfSectionContainer>
          <img src={ContactIllustration} alt='promotion illustration' className='z-index-1' loading='lazy' />
        </StyledContactCard>
      </Container>
    </StyledContactCardWrapper>
  );
};

export default ContactCard;
