import styled from 'styled-components';

export const Container = styled.div`
  width: 1500px;
  max-width: 95%;
  margin: 0 auto;

  &.center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.center-middle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.cards {
    width: 1540px;
  }
`;

export const SectionContainer = styled.div`
  width: 750px;
  max-width: 95%;
  text-align: center;
  margin: auto;

  @media ${(props) => `${props.theme.device.laptop}`} {
    width: 500px;
    text-align: left;
    margin: 0;
  }

  @media ${(props) => `${props.theme.device.laptopM}`} {
    width: 650px;
  }

  @media ${(props) => `${props.theme.device.laptopL}`} {
    width: 750px;
  }
`;
 
export const HalfSectionContainer = styled.div`
  width: 750px;
  max-width: 95%;
  text-align: center;
  margin: auto;
  z-index: 1;

  @media ${(props) => `${props.theme.device.laptop}`} {
    width: 50%;
    margin: 0;
    text-align: left;
  }
`;