import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import styled from 'styled-components';

import { SectionContainer, Container } from '../../shared/components/UI/Container';
import { ReactComponent as ZigZag } from '../../assets/zigzag.svg';
import { StyledNativeLinkButton } from '../../shared/components/UI/StyledLink';

const DemoSection = styled.section`
  padding: 150px 0;
  position: relative;

  & > ${Container} {
    display: flex;
    flex-direction: column;
    align-items: center;

    ${SectionContainer} {
      margin-bottom: 20px;

      @media ${(props) => `${props.theme.device.laptop}`} {
        margin-bottom: 0px;
      }
    }

    @media ${(props) => `${props.theme.device.laptop}`} {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
`;

const ProductDemo = ({ product }) => {
  const { demo, assets, id, demoLink, hasDemo } = product;

  return (
    <DemoSection color={id}>
      <ZigZag className='zigzag' />
      <Container className='mb-5'>
        <SectionContainer>
          <h4 className='grey regular fw-normal'>{assets} Total Assets</h4>
          <h2 className='black'>Some illustrations in this pack</h2>
        </SectionContainer>
        {hasDemo && (
          <div>
            <StyledNativeLinkButton href={demoLink} target='_blank'>
              Demo
              <BsArrowRight className='ms-05' />
            </StyledNativeLinkButton>
          </div>
        )}
      </Container>
      <DemoCards demoData={demo} colorId={id} />
    </DemoSection>
  );
};

const StyledDemoCards = styled.div`
  margin: 0 -20px 0 -20px;
  & > ${Container} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const DemoCards = ({ demoData, colorId }) => {
  const renderCards = demoData.map((demoItem, index) => (
    <DemoCardItem key={index} demoTitle={demoItem.title} demoImage={demoItem.image} colorId={colorId} />
  ));

  return (
    <StyledDemoCards>
      <Container className='cards'>{renderCards}</Container>
    </StyledDemoCards>
  );
};

const StyledDemoCardItem = styled.div`
  background: ${(props) => `${props.theme.colors.bgDarkGrey}`};
  /* background: ${(props) => `${props.theme.colors.cardColors[props.color - 1]}`}; */
  padding: 30px 40px;
  width: 100%;
  margin: 20px;
  text-align: center;
  cursor: default;
  overflow: hidden;
  border-radius: ${(props) => `${props.theme.cardBorderRadius}`};
  transition: ${(props) => `${props.theme.transition}`};

  & > * {
    cursor: default;
  }

  &:hover {
    transform: scale(1.03);
    box-shadow: ${(props) => `${props.theme.cardBoxShadow}`};
  }

  @media ${(props) => `${props.theme.device.mobileL}`} {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${(props) => `${props.theme.device.tablet}`} {
    max-width: calc(50% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }

  @media ${(props) => `${props.theme.device.laptop}`} {
    max-width: calc(33.333% - 40px);
  }

  /* @media ${(props) => `${props.theme.device.laptopM}`} {
    max-width: calc(25% - 40px);
  } */

  img {
    width: 100%;
    max-width: 300px;
  }
`;

const DemoCardItem = ({ demoTitle, demoImage, colorId }) => {
  return (
    <StyledDemoCardItem color={colorId}>
      <h3 className='black mb-2'>{demoTitle}</h3>
      <img src={demoImage} alt={demoTitle} loading='lazy' />
    </StyledDemoCardItem>
  );
};

export default ProductDemo;
