import React from 'react';
import styled from 'styled-components';

const StyledImageLink = styled.a`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 2rem;
  cursor: pointer;
  
  img {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  @media ${(props) => `${props.theme.device.mobileL}`} {
    width: 33.333%;
  }
`;

const ImageLink = ({link, image, text}) => {
  return (
    <StyledImageLink href={link}>
      <img src={image} alt={`${text} social media link`} loading='lazy' />
      <p>{text}</p>
    </StyledImageLink>
  );
};

export default ImageLink;
