import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
  }

  html {
    font-size: 50%;
    overflow-x: hidden;
    width: 100%;
    /* scroll-behavior: smooth; */

    @media ${(props) => `${props.theme.device.laptopL}`} {
      font-size: 62.5%;
    }
  }

  body {
    background: ${(props) => `${props.theme.colors.white}`};
    color: ${(props) => `${props.theme.colors.textBlack}`};;
    font-size: 1.6rem;
    overflow-x: hidden;
    width: 100%;
  }

  a {
    color: ${(props) => `${props.theme.colors.textWhite}`};
    text-decoration: none;
    font-weight: 600;
  }

  h1 {
    font-size: 4rem;

    @media ${(props) => `${props.theme.device.laptop}`} {
      font-size: 5rem;
    }

    @media ${(props) => `${props.theme.device.laptopL}`} {
      font-size: 6rem;
    }
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 2.2rem;
  }

  label {
    font-weight: 600;
  }

  p {
    line-height: 30px;
  }

  h1, h2, h3, h4, h5, h6, p, label, a{
    &.white {
      color: ${(props) => `${props.theme.colors.textWhite}`};
    }

    &.black {
      color: ${(props) => `${props.theme.colors.textBlack}`};
    }

    &.primary {
      color: ${(props) => `${props.theme.colors.textPrimary}`};
    }

    &.grey {
      color: ${(props) => `${props.theme.colors.textGrey}`};
    }

    &.fw-normal {
      font-weight: normal;
    }

    &.line-through {
      text-decoration: line-through;
    }
  }
  
  /* bg elements */
  .ellipse {
    position: absolute;
    opacity: 0.2;

    &.top {
      top: -100px;
    }

    &.bottom {
      bottom: -100px;
    }

    &.bottom-low {
      bottom: -270px;
    }

    &.left {
      left: -100px;
    }

    &.right {
      right: -100px;      
    }

    &.large {
      width: 300px;
    }

    &.medium {
      width: 200px;
    }

    &.small {
      width: 150px;
    }

    &.middle-right {
      right: 150px;
    }

    &.middle-bottom {
      bottom: 0px;
    }

    &.opacity-1 {
      opacity: 1;
    }
  }

  .zigzag {
    position: absolute;
    width: 100%;
    height: auto;
    top: 100px;
    z-index: -1;
  }

  /* absolute illustrations */
  .responsive-illustration {
    width: 100%;
    max-width: 500px;
    @media ${(props) => `${props.theme.device.laptopM}`} {
      width: auto;
    }
  }

  .illustration {
    position: absolute;
    width: 500px;

    @media ${(props) => `${props.theme.device.laptopM}`} {
      width: auto;
    }

    &.bottom {
      bottom: 0px;
    }

    &.right {
      right: 0%;
    }

    &.right-high {
      right: 10%;
    }

    &.vertical-center {
      top: 50%;
      transform: translateY(-50%);
    }

    &.overflown-left {
      left: -20px;
    }

    &.overflown-right {
      right: -20px;
    }
  }

  /* utilities */
  .relative {
    position: relative;
  };

  .d-flex {
    display: flex;
  }

  .align-items {
    align-items: center;
  }

  .justify-content {
    justify-content: center;
  } 

  .z-index-0 {
    z-index: 0;
  }

  .z-index-1 {
    z-index: 1;
  }

  .n-z-index-1 {
    z-index: -1;
  }

  .z-index-100 {
    z-index: 100;
  }

  /* margins */
  .me-05 {
    margin-right: 0.5rem;
  }

  .me-1 {
    margin-right: 1rem;
  }

  .me-2 {
    margin-right: 2rem;
  }

  .ms-05 {
    margin-left: 0.5rem;
  }

  .ms-1 {
    margin-left: 1rem;
  }

  .ms-2 {
    margin-left: 2rem;
  }

  .mt-05 {
    margin-top: 0.5rem;
  }

  .mt-1 {
    margin-top: 1rem;
  }

  .mt-2 {
    margin-top: 2rem;
  }

  .mb-05 {
    margin-bottom: 0.5rem;
  }

  .mb-1 {
    margin-bottom: 1rem;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .mb-3 {
    margin-bottom: 3rem;
  }

  .mb-4 {
    margin-bottom: 4rem;
  }

  .mb-5 {
    margin-bottom: 5rem !important;
  }

  .mb-6 {
    margin-bottom: 6rem !important;
  }

  .mb-10 {
    margin-bottom: 10rem !important;
  }

  /* Animation Classes */

  .drawer-animation-enter {
    opacity: 0;
    left: -100%;

    .close-icon {
      top: -100px; 
    }
  }

  .drawer-animation-enter-active {
    left: 0;
    opacity: 1;
    transition: all 300ms ease-in-out;
    
    .close-icon {
      top: 35px; 
      transition: all 300ms ease-in-out;
    }
  }

  .drawer-animation-exit {
    opacity: 1;
    left: 0;
    
    .close-icon {
      top: 35px; 
    }
  }

  .drawer-animation-exit-active {
    left: -100%;
    opacity: 0;
    transition: all 300ms ease-in-out; 

    .close-icon {
      top: -100px; 
      transition: all 300ms ease-in-out;
    }
  }
`;

export default GlobalStyle;
