import React from 'react';
import { IoStarOutline, IoChatbubbleEllipsesOutline, IoMailOutline } from 'react-icons/io5';
import { BsArrowRight } from 'react-icons/bs';

import Header from './Header';
import { StyledLinkBorder, StyledNativeLinkButton } from '../UI/StyledLink';


const Navigation = () => {
  return (
    <Header>
      <StyledLinkBorder to='/'>
        <IoStarOutline className="me-05"/>
        Discover
      </StyledLinkBorder>
      <StyledLinkBorder to='/about'>
        <IoChatbubbleEllipsesOutline className="me-05"/>
        About
      </StyledLinkBorder>
      <StyledLinkBorder to='/contact'>
        <IoMailOutline className="me-05"/>
        Contact
      </StyledLinkBorder>
      <StyledNativeLinkButton href='https://koaasy.gumroad.com/l/iexrq'>
        Get All Illustrations
        <BsArrowRight className="ms-05"/>
      </StyledNativeLinkButton>
    </Header>
  );
};

export default Navigation;
