import React, { Fragment } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import styled from 'styled-components';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import { Container, SectionContainer } from '../../shared/components/UI/Container';
import { StyledNativeLinkButton } from '../../shared/components/UI/StyledLink';
import { ReactComponent as Ellipse } from '../../assets/ellipse.svg';
import DiscoverIllustration from '../../assets/discover.png';
import Products from '../components/Products';
import PromotionCard from '../../shared/components/InfoCards/PromotionCard';
import ContactCard from '../../shared/components/InfoCards/ContactCard';

const DiscoverSection = styled.section`
  position: relative;
  overflow: hidden;
  background-color: ${(props) => `${props.theme.colors.secondary}`};
  height: 100vh;
  display: flex;
  align-items: center;
  ${Container} {
    img {
      display: none;

      @media ${(props) => `${props.theme.device.laptop}`} {
        display: block;
      }
    }
  }
`;

const Discover = () => {

  gsap.registerPlugin(ScrollToPlugin);

  const goToSection = () => {
    gsap.to(window, {duration: 1, scrollTo:"#productSection"});
  }

  return (
    <Fragment>
      <DiscoverSection>
        <Ellipse className='ellipse large top right' />
        <Ellipse className='ellipse large bottom left' />
        <Container className='relative z-index-1 center'>
          <SectionContainer>
            <h1 className='white mb-3'>Discover more than 100 illustrations in different styles and formats</h1>
            <p className='mb-5 white'>
              Get access to a big library of handmade, fun and colorful illustrations in different styles. The illustrations are ready for personal or commercial projects and many more illustration packs are added every month. What are you waiting for?
            </p>
            <StyledNativeLinkButton onClick={goToSection}>
              Learn More
              <BsArrowRight className='ms-05' />
            </StyledNativeLinkButton>
          </SectionContainer>
          <img
            src={DiscoverIllustration}
            className='responsive-illustration'
            alt='discover illustration'
            loading='lazy'
          />
        </Container>
      </DiscoverSection>
      <Products />
      <ContactCard />
      <PromotionCard />
    </Fragment>
  );
};

export default Discover;
