import React from "react";
import { ThemeProvider } from "styled-components";

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1280px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const theme = {
  colors: {
    white: "#FFFFFF",
    black: "#353B40",
    
    primary: "#F8DF00",
    primaryDark: "#EED600",
    secondary: "#52A89E",
    secondaryLight: "#40c5e2",
    secondaryDark: "#419389",

    textWhite: '#FFFFFF',
    textLightGrey: "#FAF5F5",
    textPrimary: '#1D69AE',
    textGrey: '#A1A1A1',
    textBlack: '#353B40',

    blue:'#1D69AE',
    darkBlue:'#14528B',

    bodyColor: '#FFFFFF',
    bgGrey: '#FAFAFA',
    bgDarkGrey: '#ECECEC',

    cardColors: [
      '#338ADB',
      '#008C9F',
      '#14B7DB',
      '#3BCDB3',
      '#AF89CD',
      '#438BCD',
      '#9D64C9',
      '#EE4D91',
    ],  
  },

  footer: '#F8F8F8',
  headerLight: 'rgba(82,168,158, 0.8)',
  headerDark: 'rgba(65,147,137, 0.8)',
  cardBorderRadius: '10px',
  cardBoxShadow: '0px 10px 30px rgba(0, 0, 0, 0.15)',
  actionBorderRadius: '9999px',
  actionBoxShadow: '0px 10px 30px rgba(0, 0, 0, 0.15)',
  transition: `all 0.3s ease-out`,
  device: {
    mobileS: `(min-width: ${size.mobileS})`, // '320px'
    mobileM: `(min-width: ${size.mobileM})`, // '375px'
    mobileL: `(min-width: ${size.mobileL})`, // '425px'
    tablet: `(min-width: ${size.tablet})`, // '768px'
    laptop: `(min-width: ${size.laptop})`, // '1024px'
    laptopM: `(min-width: ${size.laptopM})`, // '1280px'
    laptopL: `(min-width: ${size.laptopL})`, //'1440px'
    desktop: `(min-width: ${size.desktop})`, // '2560px'
    desktopL: `(min-width: ${size.desktop})` // '2560px'
  }
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;