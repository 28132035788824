import React from 'react'
import styled from 'styled-components'

const StyledIcon = styled.a`
  display: block;
  border-radius: 50%;
  color: ${(props) => `${props.theme.colors.white}`};
  display: inline-flex;
  padding: 5px;
  background-color: ${(props) => `${props.theme.colors.blue}`};
  transition: ${(props) => `${props.theme.transition}`};

  &:hover {
    background-color: ${(props) => `${props.theme.colors.darkBlue}`};
    color: ${(props) => `${props.theme.colors.white}`};
  }
`;

const IconLink = ({link, icon, ...props}) => {
  return (
    <StyledIcon href={link} target="_blank" {...props}>
      {icon}
    </StyledIcon>
  )
}

export default IconLink
