import React from 'react';
import styled from 'styled-components';

import { Container } from '../../shared/components/UI/Container';
import { ReactComponent as Ellipse } from '../../assets/greyEllipse.svg';

const LicensingSection = styled.section`
  position: relative;
  background-color: ${(props) => `${props.theme.colors.bgGrey}`};
  overflow: hidden;

  ${Container} {
    padding: 200px 0 70px 0;

    div {
      margin-bottom: 5rem;
    }

    h3 {
      margin-bottom: 3rem;
    }

    p,
    li {
      margin-bottom: 3rem;
    }

    ul {
      margin-left: 3rem;
    }
  }
`;

const Licensing = () => {
  return (
    <LicensingSection className='relative'>
      <Ellipse className='ellipse large top right opacity-1' />
      <Ellipse className='ellipse large bottom left opacity-1' />
      <Container className='relative'>
        <h1 className='black mb-5'>License</h1>
        <div>
          <h3>Licensing Agreement</h3>
          <p>
            By purchasing any of the illustration packs you are being given a license to use the illustration packs under
            certain conditions.
          </p>
        </div>

        <div>
          <h3>What you are allowed to do</h3>
          <ul>
            <li>
              This is a 'multi-use' license, which means you may use the set of illustrations purchased multiple times,
              in multiple commercial or personal projects.
            </li>
            <li>
              You may use the illustrations in non-editable formats for demonstrating purposes of your own products.
            </li>
            <li>You may use the illustrations create a project or product for personal or commercial use.</li>
            <li>
              You may modify colors, style or the look & feel of the illustration to match your brand, product and
              requirements.
            </li>
            <li>
              You may combine the illustrations with other works and make a derivative work from it. The resulting works
              are subject to the terms and conditions of this license.
            </li>
          </ul>
        </div>

        <div>
          <h3>What you are not allowed to do</h3>
          <ul>
            <li>
              You are not allowed to sell, redistribute, lease, license, sub-license the illustration as a stock,
              template, on marketplaces or anywhere else, you are not allowed to do this with any of the illustration
              assets either on its own or bundled with other items, this also applies even if you modify the Item or
              offer it for free.
            </li>
            <li>
              You cannot sell or redistribute any of the illustrations as your own product or share source files with
              other people.
            </li>
            <li>You are not allowed to share a single license with multiple users.</li>
            <li>You are not allowed to modify the original illustrations in order to resell under its own brand.</li>
          </ul>
        </div>

        <div>
          <h3>Other conditions and terms</h3>
          <p>You are not required to attribute me or link the purchased illustrations in any of your projects.</p>
          <p>
            Only a full version of any of the illustrations can be used in commercial products or projects. A free
            version of illustrations may be used for personal purposes only.
          </p>
          <p>We are not responsible for any outcome that may occur during the course of usage of our resources.</p>
        </div>

        <div>
          <h3>License termination</h3>
          <p>
            This license can be terminated at any time, if you breach the terms and conditions. In case that happens,
            you must stop using the assets and delete it from your computers and other devices.
          </p>
        </div>
      </Container>
    </LicensingSection>
  );
};

export default Licensing;
