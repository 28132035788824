import React from 'react';
import { AiOutlineInstagram, AiFillYoutube } from 'react-icons/ai';
import styled from 'styled-components';

import IconLink from '../UI/IconLink';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: min-content;

  @media ${(props) => `${props.theme.device.tablet}`} {
    margin-right: 1rem;
    margin-bottom: 0;
  }
`;

const SocialMedia = () => {
  return (
    <IconContainer>
      <IconLink
        className='me-1'
        link='https://www.youtube.com/channel/UCym1vEEOkhMQvnr40u0UgkQ'
        icon={<AiFillYoutube size='25' />}
      />
      <IconLink link='https://www.instagram.com/koaasy/?hl=en' icon={<AiOutlineInstagram size='25' />} />
    </IconContainer>
  );
};

export default SocialMedia;
