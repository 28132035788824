import React, { Fragment, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IoMenuSharp } from 'react-icons/io5';
import { gsap, Power2 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { StyledLinkBorder } from '../UI/StyledLink';
import { Container } from '../UI/Container';
import { ReactComponent as Mylogo } from '../../../assets/logo-rounded.svg';
import Drawer from './Drawer';

const StyledHeader = styled.header`
  padding: 1.5rem 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s ease-in;

  &.active {
    background: ${(props) => (props.isBgWhite ? `${props.theme.headerLight}` : `${props.theme.headerDark}`)};
    backdrop-filter: blur(4px);
    
    & a {
      color: ${(props) => (props.isBgWhite ? `${props.theme.colors.white}` : `${props.theme.colors.white}`)};

      &:hover {
        border-color: inherit;
      }
    }
  }
`;

const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h1 {
    font-size: 3rem;
  }

  & a {
    display: flex;
    align-items: center;
    color: ${(props) => (props.isBgWhite ? `${props.theme.colors.black}` : `${props.theme.colors.textWhite}`)};
    font-weight: 600;
  }

  ${StyledLinkBorder} {
    &:hover {
      border: ${(props) =>
        props.isBgWhite ? `1px solid ${props.theme.colors.black}` : `1px solid ${props.theme.colors.white}`};
    }
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .menu-icon {
    cursor: pointer;
    color: ${(props) => (props.isBgWhite ? `${props.theme.colors.black}` : `${props.theme.colors.white}`)};
    @media ${(props) => `${props.theme.device.laptop}`} {
      display: none;
    }
  }
`;

const StyledNav = styled.nav`
  display: none;

  @media ${(props) => `${props.theme.device.laptop}`} {
    display: flex;
    align-items: center;
  }
`;

const Header = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpened] = useState(false);
  const [isBgWhite, setIsBgWhite] = useState(false);
  const headerRef = useRef(null);
  const { pathname } = useLocation();

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const headerAnimation = gsap
      .from(headerRef.current, {
        yPercent: -100,
        duration: 0.2,
        ease: Power2.easeIn,
      })
      .progress(1);

    ScrollTrigger.create({
      start: 'top top-=' + 100,
      end: 99999,
      onLeave: () => {
        headerRef.current.classList.remove('active');
      },
      onUpdate: (self) => {
        headerRef.current.classList.add('active');
        if (self.direction === -1) {
          headerAnimation.play();
        } else {
          headerAnimation.reverse();
        }

        if (self.progress === 0) {
          headerRef.current.classList.remove('active');
        }
      },
    });
  }, []);

  useEffect(() => {
    if (pathname === '/faq' || pathname === '/licensing') {
      setIsBgWhite(true);
    } else {
      setIsBgWhite(false);
    }
  }, [pathname]);

  const toggleDrawer = () => {
    setIsDrawerOpened((previousState) => !previousState);
  };

  return (
    <Fragment>
      {/* {isDrawerOpened && <Drawer onToggleDrawer={toggleDrawer} ref={drawerRef}/>} */}
      <Drawer onToggleDrawer={toggleDrawer} show={isDrawerOpen} />
      <StyledHeader className='z-index-100' ref={headerRef} isBgWhite={isBgWhite}>
        <HeaderContainer isBgWhite={isBgWhite}>
          <h1>
            <Link to='/'>
              <Mylogo className='me-1' width='65' />
              Koaasy
            </Link>
          </h1>
          <StyledNav>{children}</StyledNav>
          <IoMenuSharp size={40} className='menu-icon' onClick={toggleDrawer} />
        </HeaderContainer>
      </StyledHeader>
    </Fragment>
  );
};

export default Header;
