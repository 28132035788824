import React from 'react';
import styled from 'styled-components';

import { Container } from '../../shared/components/UI/Container';
import { ReactComponent as Ellipse } from '../../assets/greyEllipse.svg';

const FAQSection = styled.section`
  position: relative;
  background-color: ${(props) => `${props.theme.colors.bgGrey}`};
  overflow: hidden;

  ${Container} {
    padding: 200px 0 70px 0;

    div {
      margin-bottom: 5rem;
    }

    h3 {
      margin-bottom: 3rem;
    }

    p,
    li {
      margin-bottom: 3rem;
    }

    ul {
      margin-left: 3rem;
    }

    a {
      color: ${(props) => `${props.theme.colors.black}`};
      text-decoration: underline;
    }
  }
`;

const FAQ = () => {
  return (
    <FAQSection className='relative'>
      <Ellipse className='ellipse large top right opacity-1' />
      <Ellipse className='ellipse large bottom left opacity-1' />
      <Container className='relative'>
        <h1 className='black mb-5'>FAQ</h1>
        <div>
          <h3>Where can I get an invoice?</h3>
          <p>
            Payments are processed on Gumroad and to receive an invoice for your purchase, please click <a href="https://customers.gumroad.com/receipts-and-refunds/i-need-an-invoice">here</a>.
          </p>
        </div>

        <div>
          <h3>Do you offer refunds?</h3>
          <p>
            No, digital products cannot usually be returned, therefore all product purchases are non-refundable.
          </p>
        </div>

        <div>
          <h3>Can you make a custom illustration for me?</h3>
          <p>
            Yes, I do custom work. please contact me on <a href="mailto:koaasy@gmail.com">koaasy@gmail.com</a> and let's talk about your next project.
          </p>
        </div>

        <div>
          <h3>Can I use a single license for multiple users?</h3>
          <p>
            No. All products purchases come with a single license, which means one license is exclusive for one person.
          </p>
        </div>
      </Container>
    </FAQSection>
  );
};

export default FAQ;
