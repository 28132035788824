import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  border-radius: ${(props) => `${props.theme.actionBorderRadius}`};
  transition: ${(props) => `${props.theme.transition}`};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLinkBorder = styled(StyledLink)`
  padding: 0.4rem 1rem;
  border: 1px solid transparent;
  color: ${(props) => `${props.theme.colors.textBlack}`};
`;

export const StyledLinkButton = styled(StyledLink)`
  box-shadow: ${(props) => `${props.theme.actionBoxShadow}`};
  min-width: 200px;
  padding: 1.5rem 2rem;
  color: ${(props) => `${props.theme.colors.textBlack} !important`};
  background-color: ${(props) => `${props.theme.colors.primary}`};

  &:hover {
    background-color: ${(props) => `${props.theme.colors.primaryDark}`};
  }
`;

// Native Link

export const StyledNativeLink = styled.a`
  border-radius: ${(props) => `${props.theme.actionBorderRadius}`};
  transition: ${(props) => `${props.theme.transition}`};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const StyledNativeLinkBorder = styled(StyledNativeLink)`
  padding: 0.4rem 1rem;
  border: 1px solid transparent;
`;

export const StyledNativeLinkButton = styled(StyledNativeLink)`
  box-shadow: ${(props) => `${props.theme.actionBoxShadow}`};
  min-width: 200px;
  padding: 1.5rem 2rem;
  cursor: pointer;
  color: ${(props) => `${props.theme.colors.textBlack} !important`};
  background-color: ${(props) => `${props.theme.colors.primary}`};

  &:hover {
    background-color: ${(props) => `${props.theme.colors.primaryDark}`};
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
`;
