import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Discover from './discover/pages/Discover';
import About from './about/pages/About';
import Contact from './contact/pages/Contact';
import FAQ from './FAQ/pages/FAQ';
import Licensing from './licensing/pages/Licensing';
import GlobalStyle from './shared/components/UI/GlobalStyles';
import Navigation from './shared/components/Navigation/Navigation';
import Theme from './shared/components/UI/Theme';
import Product from './product/pages/Product';
import Footer from './shared/components/Navigation/Footer';
import ScrollToTop from './shared/components/UI/ScrollToTop';
import Links from './links/pages/Links';

const App = () => {
  return (
    <HelmetProvider>
    <Theme>
      <Router>
        <Helmet>
          <title>Koaasy</title>
          <meta name='description' content='Discover more than 1000 graphic assets' />
        </Helmet>
        <ScrollToTop />
        <GlobalStyle />
        <Navigation />
        <Routes>
          <Route path='/' element={<Discover />} exact />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/licensing' element={<Licensing />} />
          <Route path='/links' element={<Links />} />
          <Route path='/product/:slug' element={<Product />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
        <Footer />
      </Router>
    </Theme>
    </HelmetProvider>
  );
};

export default App;
