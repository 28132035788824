import React, { Fragment } from 'react';
import styled from 'styled-components';
import { AiOutlineMail } from 'react-icons/ai';

import { Container, HalfSectionContainer } from '../../shared/components/UI/Container';
import PromotionCard from '../../shared/components/InfoCards/PromotionCard';
import { ReactComponent as Ellipse } from '../../assets/ellipse.svg';
import DiscoverIllustration from '../../assets/contact-now.png';
import AboutCard from '../../shared/components/InfoCards/AboutCard';
import IconLink from '../../shared/components/UI/IconLink';

const ContactSection = styled.section`
  position: relative;
  margin-bottom: 150px;
  background-color: ${(props) => `${props.theme.colors.secondary}`};
  height: 100vh;
  display: flex;
  align-items: center;

  ${Container} {
    img {
      display: none;

      @media ${(props) => `${props.theme.device.laptop}`} {
        display: block;
      }
    }

    .emailWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      @media ${(props) => `${props.theme.device.laptop}`} {
        justify-content: flex-start;
      }
    }
  }
`;

const Contact = () => {
  return (
    <div>
      <Fragment>
        <ContactSection>
          <Ellipse className='ellipse large top right' />
          <Ellipse className='ellipse large bottom left' />
          <Container className='relative z-index-1 center'>
            <HalfSectionContainer className='relative'>
              <h1 className='white mb-3'>Contact me for a project or simply to say hi.</h1>
              <p className='white mb-5'>
                Didn't find what you were looking for? No worries! Please contact me through the email below and we can discuss any ideas you have or talk about any projects you have lined up!
              </p>
              <div className='emailWrapper'>
                <IconLink href="mailto:koaasy@gmail.com" icon={<AiOutlineMail size='25'/>} className="me-1" />
                <p className='white'>koaasy@gmail.com</p>
              </div>
            </HalfSectionContainer>
            <img
              src={DiscoverIllustration}
              className='responsive-illustration'
              alt='discover illustration'
              loading='lazy'
            />
          </Container>
        </ContactSection>
        <AboutCard />
        <PromotionCard />
      </Fragment>
    </div>
  );
};

export default Contact;
