import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import styled from 'styled-components';

import { productsData } from '../../discover/components/productsData';
import { ReactComponent as Ellipse } from '../../assets/ellipse.svg';
import { StyledNativeLinkButton } from '../../shared/components/UI/StyledLink';
import { Container, SectionContainer } from '../../shared/components/UI/Container';
import PromotionCard from '../../shared/components/InfoCards/PromotionCard';
import ProductDemo from './ProductDemo';

const ProductSection = styled.section`
  position: relative;
  background: ${(props) => `${props.theme.colors.secondary}`};
  /* background: ${(props) => `${props.theme.colors.cardColors[props.color - 1]}`}; */
  ${Container} {
    padding: 150px 0 150px 0;

    @media ${(props) => `${props.theme.device.laptopL}`} {
      padding: 250px 0 150px 0;
    }

    & > img {
      display: none;

      @media ${(props) => `${props.theme.device.laptop}`} {
        display: block;
      }
    }
  }
`;

const Product = () => {
  const { slug } = useParams();

  const product = productsData.find((product) => product.slug === slug);

  const availableFormats = product.formats.map((format, index) => (
    <img src={format} alt='format icon' loading='lazy' className='me-1' key={index}/>
  ));

  return (
    <Fragment>
      <ProductSection color={product.id} >
        <Ellipse className='ellipse large top right' />
        <Ellipse className='ellipse large bottom left' />
        <Container className='relative z-index-1 center'>
          <SectionContainer>
            <h1 className='white mb-3'>{product.title}</h1>
            <p className='mb-3 white'>{product.description}</p>
            <div className='mb-5'>{availableFormats}</div>
            {product.gumRoadID && (
              <StyledNativeLinkButton href={`https://koaasy.gumroad.com/l/${product.gumRoadID}`}>
                Download now for ${product.price}
                <BsArrowRight className='ms-05' />
              </StyledNativeLinkButton>
            )}
          </SectionContainer>
          <img src={product.image} className='responsive-illustration' alt='discover illustration' loading='lazy' />
        </Container>
      </ProductSection>
      <ProductDemo product={product} />
      <PromotionCard />
    </Fragment>
  );
};

export default Product;
