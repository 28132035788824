import React from 'react';
import styled from 'styled-components';
import { StyledLink } from '../UI/StyledLink';
import { IoStarOutline, IoChatbubbleEllipsesOutline, IoMailOutline, IoCloseSharp } from 'react-icons/io5';
import { CSSTransition } from 'react-transition-group';

const StyledDrawer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: ${(props) => `${props.theme.colors.white}`};
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${StyledLink} {
    color: ${(props) => `${props.theme.colors.textBlack}`};
  }

  .close-icon {
    position: fixed;
    top: 35px;
    right: 25px;
    cursor: pointer;
    color: ${(props) => `${props.theme.colors.textBlack}`};
  }
`;

const Drawer = ({ onToggleDrawer, show }) => {
  const handleToggle = () => {
    onToggleDrawer();
  };

  return (
    <CSSTransition in={show} timeout={300} classNames="drawer-animation" mountOnEnter unmountOnExit>
      <StyledDrawer>
        <IoCloseSharp size={40} className='close-icon' onClick={handleToggle} />
        <StyledLink to='/' className='mb-4' onClick={handleToggle}>
          <IoStarOutline className='me-05' />
          Discover
        </StyledLink>
        <StyledLink to='/about' className='mb-4' onClick={handleToggle}>
          <IoChatbubbleEllipsesOutline className='me-05' />
          About
        </StyledLink>
        <StyledLink to='/contact' onClick={handleToggle}>
          <IoMailOutline className='me-05' />
          Contact
        </StyledLink>
      </StyledDrawer>
    </CSSTransition>
  );
};

export default Drawer;
