import React, { useState } from 'react';
import styled from 'styled-components';

import CardItem from './CardItem';
import { productsData } from './productsData';
import { Container } from '../../shared/components/UI/Container';

const StyledCards = styled.div`
    margin:  0 -20px 0 -20px;
    & > ${Container} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Cards = () => {
  const [products, setProducts] = useState(productsData);

  const renderCards = products.map((product) => (
    <CardItem
      key={product.id}
      id={product.id}
      title={product.title}
      assets={product.assets}
      price={product.price}
      image={product.image}
      slug={product.slug}
    />
  ));

  return (
    <StyledCards>
      <Container className='cards'>{renderCards}</Container>
    </StyledCards>
  );
};

export default Cards;
