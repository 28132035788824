import React from 'react';
import styled from 'styled-components';
import { Container, HalfSectionContainer } from '../UI/Container';

import PromotionIllustration from '../../../assets/promotion.png';
import { ReactComponent as Ellipse } from '../../../assets/ellipse.svg';
import { StyledNativeLinkButton } from '../UI/StyledLink';
import { BsArrowRight } from 'react-icons/bs';
import { productsData } from '../../../discover/components/productsData';

const StyledPromotionCardWrapper = styled.div`
  ${Container} {
    img {
      order: 2;
      width: 100%;
      max-width: 500px;
      
      @media ${(props) => `${props.theme.device.laptop}`} {
        width: 50%;
        display: block;
        order: initial;
      }
    }
  }
`;

const StyledPromotionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 10px 10px;
  position: relative;
  background: ${(props) => `${props.theme.colors.secondary}`};
  overflow: hidden;
  border-radius: ${(props) => `${props.theme.cardBorderRadius}`};

  @media ${(props) => `${props.theme.device.laptop}`} {
    padding: 10px 60px 10px 20px;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const CardPrice = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 130px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 20px;
  padding-right: 20px;
  background-color: ${(props) => `${props.theme.colors.primary}`};
  border-bottom-left-radius: 100%;
  border-top-right-radius: ${(props) => `${props.theme.cardBorderRadius}`};
  line-height: 30px;

  @media ${(props) => `${props.theme.device.laptopL}`} {
    width: 160px;
    height: 160px;
    padding-top: 30px;
    padding-right: 30px;
  }
`;

const PromotionCard = () => {
  const allProductsAmount = productsData.reduce((previous, current) => {
    return previous + current.price;
  }, 0);

  return (
    <StyledPromotionCardWrapper>
      <Container className='relative mb-10'>
        <StyledPromotionCard>
          <Ellipse className='ellipse bottom-low middle-right' />
          <img src={PromotionIllustration} className='z-index-1' alt='promotion illustration' loading='lazy' />
          <HalfSectionContainer>
            <h2 className='white mb-3'>Get all illustrations for a discounted price!</h2>
            <p className='white mb-5'>
              With the ultimate all in one illustration pack, you get access to all of the illustration packs for a
              discounted price You also receive free updates and access to all future illustrations and content in the
              site. Enjoy!
            </p>
            <StyledNativeLinkButton href='https://koaasy.gumroad.com/l/iexrq'>
              Download now for $10
              <BsArrowRight className='ms-05' />
            </StyledNativeLinkButton>
          </HalfSectionContainer>
          <CardPrice>
            <h4 className='fw-normal line-through black'>${allProductsAmount}</h4>
            <h2 className='black'>$10</h2>
          </CardPrice>
        </StyledPromotionCard>
      </Container>
    </StyledPromotionCardWrapper>
  );
};

export default PromotionCard;
