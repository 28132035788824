import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Container, SectionContainer } from '../../shared/components/UI/Container';
import PromotionCard from '../../shared/components/InfoCards/PromotionCard';
import ContactCard from '../../shared/components/InfoCards/ContactCard';
import { ReactComponent as Ellipse } from '../../assets/ellipse.svg';
import AboutIllustration from '../../assets/about.png';
import SocialMedia from '../../shared/components/UI/SocialMedia';

const AboutSection = styled.section`
  position: relative;
  background-color: ${(props) => `${props.theme.colors.secondary}`};
  margin-bottom: 150px;

  ${Container} {
    padding: 150px 0 100px 0;
  }

  ${SectionContainer} {
    background: ${(props) => `${props.theme.colors.white}`};
    padding: 50px;
    border-radius: ${(props) => `${props.theme.cardBorderRadius}`};
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${(props) => `${props.theme.device.laptop}`} {
      width: 50%;
      align-items: flex-start;
    }

    @media ${(props) => `${props.theme.device.laptopL}`} {
      width: 55%;
    }

    img {
      display: none;
      width: auto;

      @media ${(props) => `${props.theme.device.laptop}`} {
        display: block;
        right: -90%;
      }

      @media ${(props) => `${props.theme.device.laptopM}`} {
        display: block;
        right: -80%;
      }

      @media ${(props) => `${props.theme.device.laptopL}`} {
        display: block;
        right: -70%;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  .arrow-right {
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    transform: translateY(-50%);
    right: -80px;
    border-top: 90px solid transparent;
    border-bottom: 90px solid transparent;
    border-left: ${(props) => `90px solid ${props.theme.colors.white}`};

    @media ${(props) => `${props.theme.device.laptop}`} {
      display: block;
    }
  }
`;

const About = () => {
  return (
    <Fragment>
      <AboutSection>
        <Ellipse className='ellipse large top right' />
        <Ellipse className='ellipse large bottom-low middle-right' />
        <Ellipse className='ellipse small bottom left' />
        <Container className='relative'>
          <SectionContainer className='relative'>
            <img
              src={AboutIllustration}
              className='illustration n-right vertical-center z-index-1'
              alt='promotion illustration'
              loading='lazy'
            />
            <h1 className='black mb-3'>Hi, I’m Marlon </h1>
            <p className='black mb-2'>
              I am a UI Designer by day and Illustrator by night. I am originally from Colombia but I live and work in
              New Zealand.
            </p>
            <p className='black mb-2'>
              I have been motivated to share my work and even though I have made little illustrations here and there, I
              haven’t taken the initiative to actively share my work and this is about to change.
            </p>
            <p className='black mb-2'>
              As someone who loves art, I enjoy making many types of illustrations in different styles but vector and 3D
              art have caught my attention. I have decided to launch this site to offer some of my illustrations, So
              stay tuned because lots of new illustrations and graphic assets are coming.
            </p>
            <p className='black mb-2'>
              Apart from illustrating, I also enjoy doing many different things like design, coding, working out and
              gaming.
            </p>
            <p className='black mb-2'>
              Thank you for reading. If you'd like to support me in other ways, you can either follow me on social
              media, <Link className='black' to='/contact'>contact me</Link> for custom illustrations or make a donation through this{' '}
              <a className='black' target="_blank" href='https://ko-fi.com/koaasy' rel="noreferrer">link</a>.
              {/* <a className='black' href='https://koaasy.gumroad.com/l/xopezg'>link</a>. */}
            </p>
            <p className='black mb-2'>Thank you so much for your support!</p>
            <SocialMedia />
            <div className='arrow-right'></div>
          </SectionContainer>
        </Container>
      </AboutSection>
      <ContactCard />
      <PromotionCard />
    </Fragment>
  );
};

export default About;
