import React from 'react';
import styled from 'styled-components';
import { Container } from '../UI/Container';
import { LinksContainer, StyledLinkBorder } from '../UI/StyledLink';
import SocialMedia from '../UI/SocialMedia';

const StyledFooter = styled.footer`
  background: ${(props) => `${props.theme.footer}`};
  padding: 30px 0;

  ${Container} {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${(props) => `${props.theme.device.tablet}`} {
      flex-direction: row;
      justify-content: space-between;
    }

    .footer-text {
      order: 2;
      margin-top: 1rem;

      @media ${(props) => `${props.theme.device.tablet}`} {
        order: 1;
        margin-top: 0;
      }
    }
  }

  ${LinksContainer} {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 1;

    @media ${(props) => `${props.theme.device.tablet}`} {
      flex-direction: row;
      order: 2;
    }
  }

  ${StyledLinkBorder} {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @media ${(props) => `${props.theme.device.tablet}`} {
      &:not(:last-child) {
        margin-right: 2rem;
        margin-bottom: 0;
      }
    }

    &:hover {
      border: ${(props) => `1px solid ${props.theme.colors.black}`};
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter className='relative z-index-1'>
      <Container>
        <div className='footer-text'>
          <p className='black'> ©2022 Made with 💜 by Marlon</p>
        </div>
        <LinksContainer>
          <SocialMedia />
          <StyledLinkBorder to='/'>
            Discover
          </StyledLinkBorder>
          <StyledLinkBorder to='/about'>About</StyledLinkBorder>
          <StyledLinkBorder to='/contact'>Contact</StyledLinkBorder>
          <StyledLinkBorder to='/faq'>FAQ</StyledLinkBorder>
          <StyledLinkBorder to='/licensing'>Licensing</StyledLinkBorder>
        </LinksContainer>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
