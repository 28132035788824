import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledCardItem = styled(Link)`
  background: ${(props) => `${props.theme.colors.bgDarkGrey}`};
  padding: 30px 40px;
  width: 100%;
  margin: 20px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: ${(props) => `${props.theme.cardBorderRadius}`};
  transition: ${(props) => `${props.theme.transition}`};

  & > * {
    cursor: pointer;
  }

  &:hover {
    transform: scale(1.03);
    box-shadow: ${(props) => `${props.theme.cardBoxShadow}`};
  }

  @media ${(props) => `${props.theme.device.mobileL}`} {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${(props) => `${props.theme.device.tablet}`} {
    max-width: calc(50% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }

  @media ${(props) => `${props.theme.device.laptop}`} {
    max-width: calc(33.333% - 40px);
  }

  @media ${(props) => `${props.theme.device.laptopM}`} {
    max-width: calc(25% - 40px);
  }
`;

const CardPrice = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  padding-top: 12px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: ${(props) => `${props.theme.colors.secondary}`};
  border-bottom-left-radius: 100%;
`;

const CardItem = ({ id, title, assets, price, image, slug }) => {
  return (
    <StyledCardItem to={`/product/${slug}`} id={id}>
      <img src={image} alt={title} width='100%' className='mb-2' />
      <h3 className='black'>{title}</h3>
      <p className='fw-normal black'>Set of {assets} illustrations</p>
      <CardPrice>${price}</CardPrice>
    </StyledCardItem>
  );
};

export default CardItem;
