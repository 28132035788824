import React from 'react';
import styled from 'styled-components';
import { Container, HalfSectionContainer } from '../UI/Container';

import AboutIllustration from '../../../assets/about.png';
import { ReactComponent as EllipseGrey } from '../../../assets/ellipseGrey.svg';
import { StyledLinkButton } from '../UI/StyledLink';
import { BsArrowRight } from 'react-icons/bs';

const StyledAboutCardWrapper = styled.div`
  ${Container} {
    img {
      width: 100%;
      max-width: 350px;
      margin-top: 6rem;

      @media ${(props) => `${props.theme.device.laptop}`} {
        width: 50%;
        display: block;
      }
    }
  }
`;

const StyledAboutCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10px;
  position: relative;
  overflow: hidden;
  background: ${(props) => `${props.theme.colors.bgDarkGrey}`};
  border-radius: ${(props) => `${props.theme.cardBorderRadius}`};

  @media ${(props) => `${props.theme.device.laptop}`} {
    padding: 80px 80px 80px 60px;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const AboutCard = () => {
  return (
    <StyledAboutCardWrapper>
      <Container className='relative mb-10'>
        <StyledAboutCard>
          <EllipseGrey className='ellipse top right' />
          <EllipseGrey className='ellipse bottom left' />
          <HalfSectionContainer className='relative'>
            <h2 className='black mb-3'>Get to know a bit more about me.</h2>
            <p className='black mb-5'>
              Hello, how are you doing? Interested in getting to know me a little bit more? I enjoy creating amazing and
              fun illustrations, I also have many other hobbies.
            </p>
            <StyledLinkButton to='/about'>
              About me
              <BsArrowRight className='ms-05' />
            </StyledLinkButton>
          </HalfSectionContainer>
          <img src={AboutIllustration} className='z-index-1' alt='promotion illustration' loading='lazy' />
        </StyledAboutCard>
      </Container>
    </StyledAboutCardWrapper>
  );
};

export default AboutCard;
