import React from 'react';
import styled from 'styled-components';
import { IoCaretDown } from 'react-icons/io5'

import { productsData } from '../../discover/components/productsData';
import { SectionContainer, Container } from '../../shared/components/UI/Container';
import { ReactComponent as ZigZag } from '../../assets/zigzag.svg';
import { StyledLinkButton } from '../../shared/components/UI/StyledLink';
import Cards from './Cards';

const ProductsSection = styled.section`
  padding: 150px 0;
  position: relative;

  & > ${Container} {
    display: flex;
    flex-direction: column;
    align-items: center;

    ${SectionContainer} {
      margin-bottom: 20px;

      @media ${(props) => `${props.theme.device.laptop}`} {
        margin-bottom: 0px;
      }
    }

    @media ${(props) => `${props.theme.device.laptop}`} {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
`;

const Products = () => {
  return (
    <ProductsSection id='productSection'>
      <ZigZag className="zigzag"/>
      <Container className='mb-5'>
        <SectionContainer>
          <h4 className='grey regular fw-normal'>{productsData.length} Total Products</h4>
          <h2 className='black'>Browse all illustration packs</h2>
        </SectionContainer>
        {/* <div>
          <StyledLinkButton className="me-2" to="/">Categories <IoCaretDown className='ms-05'/></StyledLinkButton>
          <StyledLinkButton to="/">Filter <IoCaretDown className='ms-05'/></StyledLinkButton>
        </div> */}
      </Container>
      <Cards/>
    </ProductsSection>
  );
};

export default Products;
