// daily illustrations

import dailyIllustrationsBanner from '../../assets/products/daily-illustrations/daily-illustrations-banner.png';
import dailyIllustrationsDemo1 from '../../assets/products/daily-illustrations/daily-illustrations-demo-1.png';
import dailyIllustrationsDemo2 from '../../assets/products/daily-illustrations/daily-illustrations-demo-2.png';
import dailyIllustrationsDemo3 from '../../assets/products/daily-illustrations/daily-illustrations-demo-3.png';
import dailyIllustrationsDemo4 from '../../assets/products/daily-illustrations/daily-illustrations-demo-4.png';
import dailyIllustrationsDemo5 from '../../assets/products/daily-illustrations/daily-illustrations-demo-5.png';
import dailyIllustrationsDemo6 from '../../assets/products/daily-illustrations/daily-illustrations-demo-6.png';
import dailyIllustrationsDemo7 from '../../assets/products/daily-illustrations/daily-illustrations-demo-7.png';
import dailyIllustrationsDemo8 from '../../assets/products/daily-illustrations/daily-illustrations-demo-8.png';
import dailyIllustrationsDemo9 from '../../assets/products/daily-illustrations/daily-illustrations-demo-9.png';

// desk illustrations

import deskIllustrationsBanner from '../../assets/products/desk-illustrations/desk-illustrations-banner.png';
import deskIllustrationsDemo1 from '../../assets/products/desk-illustrations/desk-illustrations-demo-1.png';
import deskIllustrationsDemo2 from '../../assets/products/desk-illustrations/desk-illustrations-demo-2.png';
import deskIllustrationsDemo3 from '../../assets/products/desk-illustrations/desk-illustrations-demo-3.png';

// sport illustrations

import sportIllustrationsBanner from '../../assets/products/sport-illustrations/sport-illustrations-banner.png';
import sportIllustrationsDemo1 from '../../assets/products/sport-illustrations/sport-illustrations-demo-1.png';
import sportIllustrationsDemo2 from '../../assets/products/sport-illustrations/sport-illustrations-demo-2.png';
import sportIllustrationsDemo3 from '../../assets/products/sport-illustrations/sport-illustrations-demo-3.png';

// crypto 3d icons

import crypto3DIconsBanner from '../../assets/products/crypto-3d-icons/crypto-icons-banner.png';
import crypto3DIconsDemo1 from '../../assets/products/crypto-3d-icons/crypto-icons-demo-1.png';
import crypto3DIconsDemo2 from '../../assets/products/crypto-3d-icons/crypto-icons-demo-2.png';
import crypto3DIconsDemo3 from '../../assets/products/crypto-3d-icons/crypto-icons-demo-3.png';
import crypto3DIconsDemo4 from '../../assets/products/crypto-3d-icons/crypto-icons-demo-4.png';
import crypto3DIconsDemo5 from '../../assets/products/crypto-3d-icons/crypto-icons-demo-5.png';
import crypto3DIconsDemo6 from '../../assets/products/crypto-3d-icons/crypto-icons-demo-6.png';

// crypto 3d coins

import crypto3DcoinsBanner from '../../assets/products/crypto-3d-coins/crypto-coins-banner.png';
import crypto3DcoinsDemo1 from '../../assets/products/crypto-3d-coins/crypto-coins-demo-1.png';
import crypto3DcoinsDemo2 from '../../assets/products/crypto-3d-coins/crypto-coins-demo-2.png';
import crypto3DcoinsDemo3 from '../../assets/products/crypto-3d-coins/crypto-coins-demo-3.png';
import crypto3DcoinsDemo4 from '../../assets/products/crypto-3d-coins/crypto-coins-demo-4.png';
import crypto3DcoinsDemo5 from '../../assets/products/crypto-3d-coins/crypto-coins-demo-5.png';
import crypto3DcoinsDemo6 from '../../assets/products/crypto-3d-coins/crypto-coins-demo-6.png';
import crypto3DcoinsDemo7 from '../../assets/products/crypto-3d-coins/crypto-coins-demo-7.png';
import crypto3DcoinsDemo8 from '../../assets/products/crypto-3d-coins/crypto-coins-demo-8.png';
import crypto3DcoinsDemo9 from '../../assets/products/crypto-3d-coins/crypto-coins-demo-9.png';


// Formats

import Blender from '../../assets/formats/blender.svg';
import Xd from '../../assets/formats/xd.svg';
import Ai from '../../assets/formats/ai.svg';
import Figma from '../../assets/formats/figma.svg';
import Eps from '../../assets/formats/eps.svg';
import Svg from '../../assets/formats/svg.svg';
import Png from '../../assets/formats/png.svg';
import Jpg from '../../assets/formats/jpg.svg';

// Images Needed for each product
// - Cover Image around 500 x 500
// - 9x Images for demo low resolution 300X300

export const productsData = [
  {
    id: 1,
    slug: 'daily-illustrations',
    title: 'Daily Illustrations',
    assets: '100',
    price: 25,
    image: dailyIllustrationsBanner,
    isLiked: false,
    hasDemo: true,
    gumRoadID: 'cvimu',
    demoLink: 'https://www.figma.com/file/JTPcnTeIJUnP4Xw1o8eHbA/Daily-Illustrations-Demo?node-id=0%3A1',
    description: "Amazing pack of handmade, fun and colorful illustrations related to workspace and desk setups. Available for download in vector and raster formats and ready for personal or commercial use. Check it out!",
    formats: [
      Ai,
      Eps,
      Svg,
      Png,
      Jpg,  
    ],
    demo: [
      {title: 'Success', image: dailyIllustrationsDemo1},
      {title: 'Research', image: dailyIllustrationsDemo2},
      {title: 'Entrepreneur', image: dailyIllustrationsDemo3},
      {title: 'Exploration', image: dailyIllustrationsDemo4},
      {title: 'Firmware', image: dailyIllustrationsDemo5},
      {title: 'Learning', image: dailyIllustrationsDemo6},
      {title: 'Support', image: dailyIllustrationsDemo7},
      {title: 'Targets', image: dailyIllustrationsDemo8},
      {title: 'Film Making', image: dailyIllustrationsDemo9},
    ]
  },
  {
    id: 2,
    slug: 'desk-illustrations',
    title: 'Desk Illustrations',
    assets: '20',
    price: 10,
    image: deskIllustrationsBanner,
    isLiked: false,
    hasDemo: true,
    gumRoadID: 'tayxj',
    demoLink: 'https://www.figma.com/file/OaKfkkct8jRV92uuarQpDP/Desk-Illustrations-Demo?node-id=0%3A1',
    description: "Amazing pack of handmade, fun and colorful illustrations related to business, work and study. Available for download in vector and raster formats and ready for personal or commercial use. Check it out!",
    formats: [
      Ai,
      Eps,
      Svg,
      Png,
      Jpg,  
    ],
    demo: [
      {title: 'Video Editing', image: deskIllustrationsDemo1},
      {title: 'Design', image: deskIllustrationsDemo2},
      {title: 'Server Security', image: deskIllustrationsDemo3},
    ]
  },
  {
    id: 3,
    slug: 'sports-illustrations',
    title: 'Sports Illustrations',
    assets: '20',
    price: 15,
    image: sportIllustrationsBanner,
    isLiked: false,
    hasDemo: true,
    gumRoadID: 'vexczu',
    demoLink: 'https://www.figma.com/file/1sCssmnf3FuEKhIOU77vvE/Sport-Illustrations?node-id=0%3A1',
    description: "Amazing pack of handmade, fun and colorful illustrations related to sports. Available for download in vector and raster formats and ready for personal or commercial use. Check it out!",
    formats: [
      Ai,
      Eps,
      Svg,
      Png,
      Jpg,  
    ],
    demo: [
      {title: 'Tennis', image: sportIllustrationsDemo1},
      {title: 'Basketball', image: sportIllustrationsDemo2},
      {title: 'Soccer', image: sportIllustrationsDemo3},
    ]
  },
  {
    id: 4,
    slug: 'crypto-3d-icons',
    title: 'Crypto 3D Icons',
    assets: '30',
    price: 25,
    image: crypto3DIconsBanner,
    isLiked: false,
    hasDemo: true,
    gumRoadID: 'zjosfc',
    demoLink: 'https://www.figma.com/file/RUuZkGEtkZyIZHb5SQc3L5/Crypto-3D-Icons?node-id=0%3A1',
    description: "Amazing pack of handmade, fun and colorful 3d icons related to cryptocurrency & blockchain. Available for download in blender and raster formats and ready for personal or commercial use. Check it out!",
    formats: [
      Blender,
      Png,
    ],
    demo: [
      {title: 'Box With Key', image: crypto3DIconsDemo1},
      {title: 'Mining Cart', image: crypto3DIconsDemo2},
      {title: 'Cryptocurrency Value', image: crypto3DIconsDemo3},
      {title: 'Target', image: crypto3DIconsDemo4},
      {title: 'Market Rally', image: crypto3DIconsDemo5},
      {title: 'Market Crash', image: crypto3DIconsDemo6},
    ]
  },
  {
    id: 5,
    slug: 'crypto-3d-coins',
    title: 'Crypto 3D Coins',
    assets: '50',
    price: 20,
    image: crypto3DcoinsBanner,
    isLiked: false,
    hasDemo: true,
    gumRoadID: 'vtknzm',
    demoLink: 'https://www.figma.com/file/2lo4OGmjqyulKmIK9N91e9/Crypto-3D-Coins-Demo?node-id=0%3A1',
    description: "Amazing pack of handmade, fun and colorful 3d icon coins related to cryptocurrency & blockchain. Available for download in blender and raster formats and ready for personal or commercial use. Check it out!",
    formats: [
      Blender,
      Png, 
    ],
    demo: [
      {title: 'Litecoin', image: crypto3DcoinsDemo1},
      {title: 'Stacks', image: crypto3DcoinsDemo2},
      {title: 'Chainlink', image: crypto3DcoinsDemo3},
      {title: 'Tether', image: crypto3DcoinsDemo4},
      {title: 'Tron', image: crypto3DcoinsDemo5},
      {title: 'Bitcoin Cash', image: crypto3DcoinsDemo6},
      {title: 'Dash', image: crypto3DcoinsDemo7},
      {title: 'Axie Infinity', image: crypto3DcoinsDemo8},
      {title: 'Polygon', image: crypto3DcoinsDemo9},
    ]
  },
]